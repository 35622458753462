.ml-card-1 {
  margin-right: 30px;
}
.ml-card-2 {
  margin: 0 20px;
}
.ml-card-3 {
  margin-left: 30px;
}

@media screen and (max-width: 612px) {
  .ml-card-1 {
    margin-right: 0px;
  }

  .ml-card-3 {
    margin-left: 0px;
  }
}
