.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  position: relative;
  width: 60%;
  height: 10px;

  background-color: white;
  border-radius: 10px;
  overflow: hidden;
}

.loader-bar {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #6b024d;
  animation: slide 2s linear infinite;
}

@keyframes slide {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}

/* @keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */
/* 
.login-loader {
  position: relative;
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  opacity: 100;
}

.box-small {
  animation: rotate-2 2s linear infinite;
}

@keyframes rotate-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(240deg);
  }
}

.box-large {
  animation: rotate-1 2s linear infinite;
}

@keyframes rotate-2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-240deg);
  }
}

@keyframes slide {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}

.loading-animation {
  animation: slide 0.7s infinite alternate;
  width: 150px;
  height: 8px;
  border: 8px solid #aeaeae;
  border-top-color: #333333;
} */
