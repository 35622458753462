@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap'); /* Poppins */
@import url('https://fonts.googleapis.com/css2?family=Sigmar+One&display=swap'); /* Sigmar one */

html,
body {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: 'Poppins', sans-serif;
  vertical-align: baseline;
}

/* Custom scrollbar styles */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: 'white';
}

::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 24px;
}

::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

html {
  scrollbar-width: thin;
  scrollbar-color: #7b7b7b #fff;
}
